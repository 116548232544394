<template>
  <b-card-body v-if="users.data">
    <app-timeline>
      <app-timeline-item
        v-for="visit in visits"
        :key="visit.id"
        :variant="randomVariant()"
      >
        <div
          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
        >
          <h6>{{ visit.plantname }}</h6>
          <small class="text-muted">{{
            dayjs(visit.visitdate).locale(`${$i18n.locale}`).fromNow()
          }}</small>
        </div>
        <b-row v-if="isViewAmount">
          <b-col sm="12" md="3">
            {{ $t("milkAmount") }}: {{ visit.milkamount }}
          </b-col>
          <b-col sm="12" md="3">
            {{ $t("milkAvarage") }}: {{ visit.milkavarage }}
          </b-col>
          <b-col sm="12" md="3">
            {{ $t("beefCowCount") }}: {{ visit.numberofbeef }}
          </b-col>
          <b-col sm="12" md="3">
            {{ $t("dairyCowCount") }}: {{ visit.numberofdairy }} </b-col
          ><b-col sm="12" md="3">
            {{ $t("feedUsed") }}: {{ visit.feedused }}
          </b-col>
        </b-row>
        <p class="mt-2">{{ $t("report") }}: {{ visit.report }}</p>
        <div
          class="d-flex flex-column flex-sm-row justify-content-between align-items-start"
        >
          <b-media>
            <template #aside>
              <b-avatar
                :src="
                  'https://api.herdoncloud.com/User_Pictures/' +
                  getPicture(visit)
                "
              />
            </template>
            <h6>{{ visit.fullname }}</h6>
            <span class="text-muted">
              {{ getUserMobile(visit.userid) }}
            </span>
          </b-media>
          <div
            class="d-flex flex-grow-0 justify-content-end w-100 mt-2 mt-sm-0"
          >
            <div>
              <b-button
                class="d-flex w-100 justfiy-content-center text-center"
                variant="danger"
                @click="removeVisit(visit.id)"
              >
                <feather-icon icon="TrashIcon" size="16" />
              </b-button>
            </div>
          </div>
        </div>
      </app-timeline-item>
    </app-timeline>
  </b-card-body>
</template>

<script>
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { BCardBody, BMedia, BAvatar, BButton, BRow, BCol } from "bootstrap-vue";
import dayjs from "dayjs";
import tr from "dayjs/locale/tr";
import az from "dayjs/locale/az";
import ru from "dayjs/locale/ru";
import relativeTime from "dayjs/plugin/relativeTime";
import { getUserData } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BCardBody,
    BMedia,
    BAvatar,
    BButton,
    BRow,
    BCol,
  },
  props: {
    visits: {
      type: Array,
      required: true,
    },
    isViewAmount: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: function () {
    return {
      tr: tr,
      az: az,
      ru: ru,
      variant: ["success", "secondary", "info", "warning"],
      dayjs: dayjs.extend(relativeTime),
      pictureurls: [],
      loadPicture: false,
      users: [],
    };
  },
  async mounted() {
    await this.getUserPicture();

    this.loadPicture = false;
    this.loadPicture = true;
  },
  methods: {
    randomVariant() {
      return this.variant[Math.floor(Math.random() * 4)];
    },
    getPicture(visit) {
      var pictureurl = this.pictureurls.filter((x) => x.userid == visit.userid);
      return pictureurl[0].pictureurl;
    },
    getUserMobile(id) {
      var user = this.users.data.filter((x) => x.id == id);
      return user[0].mobile;
    },
    async removeVisit(id) {
      var isOk = await this.$store.dispatch("visitsModule/deleteVisit", id);
      if (isOk)
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$t("deleted", {
              type: this.$t("visit"),
            }),
          },
        });
      else
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: this.$t("notDeleted", {
              type: this.$t("visit"),
            }),
          },
        });
      this.$emit("fetchvisit");
    },
    async getUserPicture() {
      this.users = await this.$store.dispatch("usersModule/fetchUsers", {
        id: getUserData().companyid,
        q: "",
        perPage: 200,
        page: 1,
        sortBy: "",
        sortDesc: "",
        type: "",
        gender: "",
        status: "",
      });
      this.pictureurls = this.users.data.map((x) => {
        return { userid: x.id, pictureurl: x.pictureurl };
      });
    },
  },
};
</script>
